import React from 'react'
import { PATHOGEN_LABELS_API } from '../../utils/const'
import Table from '../../components/Table'
import { useTranslation } from 'react-i18next'
import { group } from 'd3-array'

const IG13Table = ({ regions }) => {
  const { t } = useTranslation()
  //let yearsWithData
  // const wrangleData = () => {
  //   const allYears = []
  //   const allCategories = []

  //   const allData = Object.entries(regions)
  //     .map(([key, value]) => {
  //       //gets the years that have data so we know what columns to make

  //       const keysToExclude = [
  //         'memberState',
  //         'iSO3',
  //         'id',
  //         'region',
  //         'incomeGroup',
  //         'uNICEFRegion'
  //       ]
  //       if (!keysToExclude.includes(key) && value !== null) {
  //         let year, antigen
  //         if (key.includes('Baseline')) {
  //           const keyArray = key.split('B')
  //           year = 'Baseline'
  //           antigen = keyArray[0]
  //         } else {
  //           year = +key.slice(-4)
  //           antigen = key.slice(0, -4)
  //           allYears.push(year)
  //         }

  //         allCategories.push(antigen)
  //         return { year, antigen, value }
  //       } else return null
  //     })
  //     .filter((item) => item)

  //   const categories = Array.from(new Set(allCategories))

  //   yearsWithData = Array.from(new Set(allYears))

  //   const formattedData = categories.map((category) => {
  //     const catData = allData
  //       .filter((d) => d.antigen === category)
  //       .reduce((acc, d) => {
  //         acc[d.year] = d.value >= 0 ? d.value : t('no data')
  //         return acc
  //       }, {})
  //     return Object.assign(
  //       {
  //         pathogen: t(PATHOGEN_LABELS[category])
  //         //pathogen: category
  //       },
  //       catData
  //     )
  //   })

  //   const sortedData = formattedData.sort((a, b) =>
  //     a.pathogen > b.pathogen ? 1 : -1
  //   )

  //   const totals = sortedData.reduce((acc, d) => {
  //     acc['Baseline']
  //       ? (acc['Baseline'] += d.Baseline)
  //       : (acc['Baseline'] = d.Baseline)

  //     yearsWithData.map((year) => {
  //       const val = d[year] === t('no data') ? 0 : d[year]
  //       return acc[year] ? (acc[year] += val) : (acc[year] = val)
  //     })
  //     return acc
  //   }, {})

  //   return [{ pathogen: t('Total'), ...totals }].concat(sortedData)
  // }

  //const ig13Data = wrangleData()
  const BASELINE_YEAR = '2018-2020'
  let years
  const wrangleIg13TableData = (data) => {
    const allYears = []
    data.forEach((d) => {
      allYears.push(d.YEAR)
      d.YEAR_RANGE === BASELINE_YEAR ? (d.year = 2020) : (d.year = d.YEAR)
      d.pathogen = d.DIMENSION2
    })
    years = Array.from(new Set(allYears.filter((year) => year)))

    const dataMap = group(data, (d) => d.pathogen)

    const groupedByPathogen = Array.from(dataMap.values())

    const formattedData = groupedByPathogen.map((pathogen) => {
      return pathogen.reduce((acc, d) => {
        acc[d.year === 2020 ? 'Baseline' : d.year] =
          d.VALUE_NUM >= 0 ? d.VALUE_NUM : t('no data')
        acc['pathogen'] =
          d.pathogen === null ? 'Total' : t(PATHOGEN_LABELS_API[d.pathogen])
        return acc
      }, {})
    })
    const sorted = formattedData
      .sort((a, b) => (a.pathogen > b.pathogen ? 1 : -1))
      .sort((a, b) => (a.pathogen === 'Total' ? -1 : 1))
    return sorted
  }

  const ig13Data = wrangleIg13TableData(regions)

  const data = React.useMemo(() => ig13Data, [ig13Data])

  const yearHeaders = years?.map((year) => {
    return {
      Header: `${year}`,
      accessor: `${year}`
    }
  })

  const columns = React.useMemo(() => {
    const baseHeader = [
      {
        Header: t('pathogen'),
        accessor: 'pathogen' // accessor is the "key" in the data
      },
      {
        Header: `${t('Baseline')} (${t('annual avg.')} ${BASELINE_YEAR})`,
        accessor: 'Baseline' // accessor is the "key" in the data
      }
    ]
    return baseHeader.concat(yearHeaders)
  }, [yearHeaders, t])

  return (
    <Table
      data={data}
      columns={columns}
      description={t(
        'A table that lists diseases and the numbers of outbreaks in each year'
      )}
    />
  )
}

export default IG13Table
